<template>
    <div>
        <div class="header-action-type">
            <div class="rounded bg-light p-2"
                 v-if="getBaseActions().includes(setup.id)">
                <font-awesome-icon icon="star"/>
            </div>
        </div>
        <div class="info-header">
            <b-button variant="info"
                      v-b-popover.hover.top="$t('settings.workflow.help.'+setup.id)"
            >
                <font-awesome-icon icon="info"/>
            </b-button>

            <b-button v-for="(error,index) in errors"
                      :key="index"
                      class="ml-2"
                      variant="danger"
                      v-b-popover.hover.top="error"
            >
                <font-awesome-icon icon="exclamation"/>
            </b-button>
        </div>
        <div class="card-devices">
            <div class="header"><h3>{{ setup.name }}</h3></div>
            <div class="body">
                <b-row>
                    <b-col v-if="optionsExtensions[setup.id]"
                           cols="12"
                    >
                        <component v-bind:is="optionsExtensions[setup.id]"
                                   v-model="setup"
                        ></component>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import bus from './../event-bus';
import {mapGetters} from "vuex";

export default {
    props: {},
    data() {
        return {
            dataId: null,
            dataNode: null,
            setup: {},
            errors: [],
            optionsExtensions: {
                'sync-bank-transactions': () => import('./../../modals/offering/workflow-options/SyncBankTransactionsOptions'),
                'map-bank-transactions': () => import('./../../modals/offering/workflow-options/MapBankTransactionsOptions'),
                'send-email': () => import('./../../modals/offering/workflow-options/SendEmailOptions'),
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            const id = this.$el.parentElement.parentElement.id;
            this.dataId = id;
            bus.$emit('getData', id);
        });

        bus.$on('SendData', (data) => {
            if (data.id === this.dataId) {
                this.dataNode = data.dataNode;
                this.setup = data.dataNode.setup;
                bus.$emit('refreshNode', this.dataId);
            }
        });

        bus.$on('SendError', (data) => {
            if ('node-' + data.id === this.dataId) {
                this.errors.push(data.error)
            }
        });

        bus.$on('ClearError', (data) => {
            this.errors = []
        });
    },
    methods: {
        ...mapGetters('Workflow', ['getActions', 'getCustomerActions', 'getBaseActions']),
        save() {
            bus.$emit('saveData', this.dataId, this.setup);
        },
    },
    watch: {
        setup: function (val) {
            if (val) {
                this.save()
            }
        },
    }
}
</script>